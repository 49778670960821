import { Injectable } from '@angular/core';
import { ToastOptions } from '@ionic/angular/standalone';
import { HashMap, TranslocoService } from '@jsverse/transloco';
import { take } from 'rxjs';

import { ToastService } from './toast.service';

@Injectable({
    providedIn: 'root',
})
export class NotificationMessageService {
    constructor(
        private toastService: ToastService,
        private translate: TranslocoService,
    ) {}

    errorMessage(
        messageKey: string,
        appendError = '',
        params?: HashMap,
        position: ToastOptions['position'] = 'bottom',
    ) {
        this.translate
            .selectTranslate(messageKey, params)
            .pipe(take(1))
            .subscribe((value) =>
                this.toastService.error(value + (appendError ? ' ' : '') + appendError, undefined, position),
            );
    }

    successMessage(messageKey: string, duration = 5000, params?: HashMap) {
        this.translate
            .selectTranslate(messageKey, params)
            .pipe(take(1))
            .subscribe((value) => this.toastService.success(value, duration));
    }
}
