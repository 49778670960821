import { inject, Injectable } from '@angular/core';
import { ToastController, ToastOptions } from '@ionic/angular/standalone';
import { TranslocoService } from '@jsverse/transloco';

@Injectable({
    providedIn: 'root',
})
export class ToastService {
    toast: HTMLIonToastElement | undefined;
    private toastController = inject(ToastController);
    private translateService = inject(TranslocoService);

    async error(message: string, duration = 12500, position: ToastOptions['position'] = 'bottom') {
        const errorText = this.translateService.translate('ERROR');
        await this.clean();

        this.toast = await this.toastController.create({
            message,
            duration,
            header: errorText,
            position,
            color: 'danger',
            cssClass: 'danger-toast',
            buttons: [
                {
                    text: 'OK',
                    role: 'cancel',
                },
            ],
        });

        return this.toast.present();
    }

    async success(message: string, duration = 5000) {
        const successText = this.translateService.translate('SUCCESS');
        await this.clean();

        this.toast = await this.toastController.create({
            message,
            duration,
            header: successText,
            position: 'bottom',
            color: 'success',
            cssClass: 'danger-toast',
            buttons: [
                {
                    text: 'OK',
                    role: 'cancel',
                },
            ],
        });

        return this.toast.present();
    }

    async toaster(config: ToastOptions) {
        await this.clean();
        this.toast = await this.toastController.create(config);
        return this.toast.present();
    }

    async clean() {
        return this.toast?.dismiss();
    }
}
