import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular/standalone';
import { TranslocoService } from '@jsverse/transloco';
import { EMPTY, from, switchMap, take } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class LoaderService {
    private loader: HTMLIonLoadingElement | undefined = undefined;

    constructor(
        private loadingController: LoadingController,
        private translate: TranslocoService,
    ) {}

    presentLoading() {
        return from(
            this.loadingController.create({
                message: this.translate.translate('PLEASE_WAIT'),
            }),
        ).pipe(
            switchMap((loader) => {
                this.loader = loader;
                return from(this.loader.present());
            }),
            take(1),
        );
    }

    dismiss() {
        if (!this.loader) {
            return EMPTY;
        }
        return from(this.loader.dismiss()).pipe(take(1));
    }
}
