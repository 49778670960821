import { getBrowserSupportedLanguage, getSupportedLanguage } from '@icp/angular/i18n';
import {
    initialStateResult,
    insertItem,
    ResultState,
    selectIsStateLoading,
    selectResult,
    selectResultOrEmptyArray,
    stateError,
    stateLoading,
    stateSuccess,
    updateItem,
} from '@icp/angular/ngrx';
import {
    AppCirkloGetUserInfoDto,
    AppEconomyCollectorDto,
    AppEconomyPaginationDto,
    AppSessionDto,
    AppStoreDto,
    CirkloGetStoreInfoResultDto,
    SupportedLanguage,
} from '@icp/interfaces';
import { createFeature, createReducer, createSelector, on } from '@ngrx/store';

import { AuthActions } from './index';

export const LANGUAGE_STORAGE_KEY = 'lang';
export const SESSION_ID_STORAGE_KEY = 'appSessionId';

export interface State {
    language: SupportedLanguage;
    // Only needed in case we did not log in using a soft key
    sessionId: string | null;
    session: ResultState<AppSessionDto>;
    userStores: ResultState<AppStoreDto[]>;
    userInfo: ResultState<AppCirkloGetUserInfoDto>;
    storeInfo: ResultState<CirkloGetStoreInfoResultDto>;
    collectorEconomies: ResultState<AppEconomyCollectorDto[]>;
    searchedEconomies: ResultState<AppEconomyPaginationDto>;
}

const langFromStorage = localStorage.getItem(LANGUAGE_STORAGE_KEY);

export const initialState: State = {
    language: (langFromStorage && getSupportedLanguage(langFromStorage)) || getBrowserSupportedLanguage(),
    sessionId: localStorage.getItem(SESSION_ID_STORAGE_KEY) ?? null,
    session: initialStateResult,
    userStores: initialStateResult,
    userInfo: initialStateResult,
    storeInfo: initialStateResult,
    collectorEconomies: initialStateResult,
    searchedEconomies: initialStateResult,
};

const reducer = createReducer(
    initialState,
    on(AuthActions.logoutSuccess, (): State => initialState),
    on(AuthActions.setLanguage, (state, action): State => ({ ...state, language: action.language })),
    on(AuthActions.listUserStores, (state): State => ({ ...state, userStores: stateLoading(state.userStores.result) })),
    on(
        AuthActions.listUserStoresSuccess,
        (state, action): State => ({
            ...state,
            userStores: stateSuccess(action.stores),
        }),
    ),
    on(
        AuthActions.listUserStoresFailure,
        (state, action): State => ({
            ...state,
            userStores: stateError(action.error, state.userStores.result),
        }),
    ),
    on(
        AuthActions.getAppSessionSuccess,
        (state, action): State => ({
            ...state,
            sessionId: action.session.id,
            session: stateSuccess(action.session),
        }),
    ),
    on(AuthActions.updateAppSession, (state): State => ({ ...state, session: stateLoading(state.session.result) })),
    on(
        AuthActions.updateAppSessionSuccess,
        (state, action): State => ({
            ...state,
            storeInfo: initialState.storeInfo,
            collectorEconomies: initialState.collectorEconomies,
            session: stateSuccess(action.session),
        }),
    ),
    on(
        AuthActions.updateAppSessionFailure,
        (state, action): State => ({
            ...state,
            session: stateError(action.error, state.session.result),
        }),
    ),
    on(
        AuthActions.getCirkloStoreInfo,
        (state): State => ({ ...state, storeInfo: stateLoading(state.storeInfo.result) }),
    ),
    on(
        AuthActions.getCirkloStoreInfoSuccess,
        (state, action): State => ({ ...state, storeInfo: stateSuccess(action.payload) }),
    ),
    on(
        AuthActions.getCirkloStoreInfoFailure,
        (state, action): State => ({
            ...state,
            storeInfo: stateError(action.error, state.storeInfo.result),
        }),
    ),
    on(
        AuthActions.patchCirkloStoreInfo,
        (state): State => ({ ...state, storeInfo: stateLoading(state.storeInfo.result) }),
    ),
    on(
        AuthActions.patchCirkloInfoStoreSuccess,
        (state, action): State => ({ ...state, storeInfo: stateSuccess(action.payload) }),
    ),
    on(
        AuthActions.patchCirkloStoreInfoFailure,
        (state, action): State => ({
            ...state,
            storeInfo: stateError(action.error, state.storeInfo.result),
        }),
    ),
    on(AuthActions.getCirkloUserInfo, (state): State => ({ ...state, userInfo: stateLoading(state.userInfo.result) })),
    on(
        AuthActions.getCirkloUserInfoSuccess,
        (state, action): State => ({ ...state, userInfo: stateSuccess(action.payload) }),
    ),
    on(
        AuthActions.getCirkloUserInfoFailure,
        (state, action): State => ({
            ...state,
            userInfo: stateError(action.error, state.userInfo.result),
        }),
    ),
    on(
        AuthActions.patchCirkloUserInfo,
        (state): State => ({ ...state, userInfo: stateLoading(state.userInfo.result) }),
    ),
    on(
        AuthActions.patchCirkloInfoUserSuccess,
        (state, action): State => ({ ...state, userInfo: stateSuccess(action.payload) }),
    ),
    on(
        AuthActions.patchCirkloUserInfoFailure,
        (state, action): State => ({
            ...state,
            userInfo: stateError(action.error, state.userInfo.result),
        }),
    ),
    on(
        AuthActions.getCollectorEconomies,
        (s): State => ({ ...s, collectorEconomies: stateLoading(s.collectorEconomies.result) }),
    ),
    on(
        AuthActions.getCollectorEconomiesSuccess,
        (s, { payload }): State => ({ ...s, collectorEconomies: stateSuccess(payload) }),
    ),
    on(
        AuthActions.getCollectorEconomiesFailed,
        (s, { error }): State => ({ ...s, collectorEconomies: stateError(error, s.collectorEconomies.result) }),
    ),
    on(
        AuthActions.searchEconomies,
        (s): State => ({ ...s, searchedEconomies: stateLoading(s.searchedEconomies.result) }),
    ),
    on(AuthActions.searchEconomiesSuccess, (s, { payload, page }): State => {
        if (page && page !== 1 && s.searchedEconomies.result) {
            return {
                ...s,
                searchedEconomies: stateSuccess({
                    ...payload,
                    results: [...s.searchedEconomies.result.results, ...payload.results],
                }),
            };
        }
        return { ...s, searchedEconomies: stateSuccess(payload) };
    }),
    on(
        AuthActions.searchEconomiesFailed,
        (s, { error }): State => ({
            ...s,
            searchedEconomies: stateError(error, s.searchedEconomies.result),
        }),
    ),
    on(
        AuthActions.appRequestStoreEconomy,
        (s): State => ({
            ...s,
            collectorEconomies: stateLoading(s.collectorEconomies.result),
        }),
    ),
    on(
        AuthActions.appRequestStoreEconomySuccess,
        (s, action): State => ({
            ...s,
            collectorEconomies: stateSuccess(updateEconomyCollectors(s.collectorEconomies.result!, action.payload)),
        }),
    ),
    on(
        AuthActions.appRequestStoreEconomyFailed,
        (s, action): State => ({
            ...s,
            collectorEconomies: stateError(action.error, s.collectorEconomies.result),
        }),
    ),
);

function updateEconomyCollectors(
    collectors: AppEconomyCollectorDto[],
    collector: AppEconomyCollectorDto,
): AppEconomyCollectorDto[] {
    const index = collectors.findIndex((c) => c.id === collector.id);
    if (index === -1) {
        return insertItem(collectors, collector);
    }
    return updateItem(collectors, collector, 'id');
}

export const authFeature = createFeature({ name: 'Auth', reducer });

export const selectUserStoresState = authFeature.selectUserStores;
export const selectUserStores = createSelector(selectUserStoresState, selectResultOrEmptyArray);
export const selectUserStoresLoading = createSelector(selectUserStoresState, selectIsStateLoading);

export const selectUserSessionState = authFeature.selectSession;
export const selectAppSessionId = authFeature.selectSessionId;
export const selectIsLoggedInAsUser = createSelector(selectAppSessionId, (sessionId) => !!sessionId);
export const selectUserSession = createSelector(selectUserSessionState, selectResult);
export const selectUserSessionUser = createSelector(selectUserSession, (session) => session?.user);
export const selectUserSessionStore = createSelector(selectUserSession, (session) => session?.store);
export const selectUserSessionStoreId = createSelector(selectUserSessionStore, (store) => store?.id);

export const selectCirkloStoreInfoResult = createSelector(authFeature.selectStoreInfo, selectResult);
export const selectCirkloStoreInfoLoading = createSelector(authFeature.selectStoreInfo, selectIsStateLoading);

export const selectCirkloUserInfoResult = createSelector(authFeature.selectUserInfo, selectResult);
export const selectCirkloUserInfoLoading = createSelector(authFeature.selectUserInfo, selectIsStateLoading);

export const selectCollectorEconomiesState = authFeature.selectCollectorEconomies;
export const selectCollectorEconomies = createSelector(selectCollectorEconomiesState, selectResultOrEmptyArray);
export const selectCollectorEconomiesMap = createSelector(
    selectCollectorEconomies,
    (economies) => new Map(economies.map((e) => [e.id, e])),
);

export const selectSearchedEconomiesState = authFeature.selectSearchedEconomies;
export const selectSearchedEconomiesResult = createSelector(selectSearchedEconomiesState, selectResult);

export const selectSearchedEconomies = createSelector(selectSearchedEconomiesResult, (r) => r?.results ?? []);
export const selectHasMoreSearchedEconomies = createSelector(selectSearchedEconomiesResult, (r) =>
    r ? r.results.length < r.total : false,
);

export const selectSearchedEconomiesLoading = createSelector(selectSearchedEconomiesState, selectIsStateLoading);

export const selectSearchedCollectorEconomies = createSelector(
    selectSearchedEconomies,
    selectCollectorEconomiesMap,
    (searched, collectorEconomies) =>
        searched.map((economy) => ({
            economy,
            status: collectorEconomies.get(economy.id)?.status,
        })),
);
