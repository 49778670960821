import { effect, inject, Injectable } from '@angular/core';
import { IdpService } from '@icp/angular/idp';
import {
    AppCirkloService,
    AppEconomiesService,
    AppSessionsService,
    AppStoresService,
    Configuration,
    Oauth2SignOutQueryDto,
} from '@icp/interfaces';
import { NavController } from '@ionic/angular/standalone';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { catchError, filter, from, map, switchMap, tap } from 'rxjs';

import { environment } from '../../../environments/environment';
import { NotificationMessageService } from '../../core/services';
import { ErrorService } from '../../shared/error.service';
import * as AuthActions from './auth.actions';
import { authFeature, LANGUAGE_STORAGE_KEY, selectAppSessionId, SESSION_ID_STORAGE_KEY } from './auth.feature';

@Injectable({ providedIn: 'root' })
export class AuthEffects {
    private actions$ = inject(Actions);
    private store = inject(Store);
    private navCtrl = inject(NavController);
    private idpService = inject(IdpService);
    private storesService = inject(AppStoresService);
    private economiesService = inject(AppEconomiesService);
    private appSessionsService = inject(AppSessionsService);
    private errorService = inject(ErrorService);
    private appCirkloService = inject(AppCirkloService);
    private notificationMessageService = inject(NotificationMessageService);
    private sessionId = this.store.selectSignal(selectAppSessionId);
    private config = inject(Configuration);

    constructor() {
        effect(() => {
            const sessionId = this.sessionId();
            if (sessionId) {
                this.config.credentials['App-Session'] = sessionId;
            } else {
                delete this.config.credentials['App-Session'];
            }
        });
    }

    logout$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.logout),
            switchMap((action) =>
                from(this.idpService.logout()).pipe(
                    map(() => AuthActions.logoutSuccess()),
                    tap(() => {
                        localStorage.removeItem(SESSION_ID_STORAGE_KEY);
                        const url = new URL(`${environment.idpURL}/idp/oauth2/v1/sign-out`);
                        const params: Oauth2SignOutQueryDto = {
                            client_id: environment.idpClientId,
                            post_logout_redirect_uri: window.location.origin,
                        };
                        for (const [key, value] of Object.entries(params)) {
                            url.searchParams.append(key, value);
                        }
                        window.location.href = url.toString();
                    }),
                    catchError((error) => this.errorService.handleError(action, AuthActions.logoutFailed, error)),
                ),
            ),
        ),
    );
    storeLanguageInStorage$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AuthActions.setLanguage),
                concatLatestFrom(() => this.store.select(authFeature.selectLanguage)),
                filter(([action, currentLang]) => action.language === currentLang),
                tap(([action]) => localStorage.setItem(LANGUAGE_STORAGE_KEY, action.language)),
            ),
        { dispatch: false },
    );

    listUserStores$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.listUserStores),
            switchMap((action) =>
                this.storesService.appListUserStores().pipe(
                    map((stores) => AuthActions.listUserStoresSuccess({ stores })),
                    catchError((error) =>
                        this.errorService.handleError(action, AuthActions.listUserStoresFailure, error),
                    ),
                ),
            ),
        ),
    );

    getAppSession$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.getAppSession),
            switchMap((action) =>
                this.appSessionsService.appGetSession().pipe(
                    map((session) => AuthActions.getAppSessionSuccess({ session })),
                    catchError((error) =>
                        this.errorService.handleError(action, AuthActions.getAppSessionFailure, error),
                    ),
                ),
            ),
        ),
    );

    updateAppSession$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.updateAppSession),
            switchMap((action) =>
                this.appSessionsService.appUpdateSessionProfile(action.payload).pipe(
                    map((session) => AuthActions.updateAppSessionSuccess({ session })),
                    tap(() => this.navCtrl.navigateRoot('/')),
                    catchError((error) =>
                        this.errorService.handleError(action, AuthActions.updateAppSessionFailure, error),
                    ),
                ),
            ),
        ),
    );

    afterGetSessionSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AuthActions.getAppSessionSuccess),
                tap((action) => localStorage.setItem(SESSION_ID_STORAGE_KEY, action.session.id)),
            ),
        { dispatch: false },
    );

    getStoreInfo$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.getCirkloStoreInfo),
            switchMap((action) =>
                this.appCirkloService.appCirkloGetStoreInfo().pipe(
                    map((payload) => AuthActions.getCirkloStoreInfoSuccess({ payload })),
                    catchError((error) =>
                        this.errorService.handleError(action, AuthActions.getCirkloStoreInfoFailure, error),
                    ),
                ),
            ),
        ),
    );

    patchStoreInfo$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.patchCirkloStoreInfo),
            switchMap((action) =>
                this.appCirkloService.appCirkloUpdateStoreInfo(action.payload).pipe(
                    map((payload) => AuthActions.patchCirkloInfoStoreSuccess({ payload })),
                    tap(() => {
                        this.navCtrl.navigateBack(action.nextUrl);
                        if (action.successMessage) {
                            this.notificationMessageService.successMessage(action.successMessage);
                        }
                    }),
                    catchError((error) =>
                        this.errorService.handleError(action, AuthActions.patchCirkloStoreInfoFailure, error),
                    ),
                ),
            ),
        ),
    );
    getUserInfo$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.getCirkloUserInfo),
            switchMap((action) =>
                this.appCirkloService.appCirkloGetUserInfo().pipe(
                    map((payload) => AuthActions.getCirkloUserInfoSuccess({ payload })),
                    catchError((error) =>
                        this.errorService.handleError(action, AuthActions.getCirkloUserInfoFailure, error),
                    ),
                ),
            ),
        ),
    );

    patchUserInfo$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.patchCirkloUserInfo),
            switchMap((action) =>
                this.appCirkloService.appCirkloUpdateUserInfo(action.payload).pipe(
                    map((payload) => AuthActions.patchCirkloInfoUserSuccess({ payload })),
                    tap(() => {
                        this.navCtrl.navigateBack(action.nextUrl);
                        if (action.successMessage) {
                            this.notificationMessageService.successMessage(action.successMessage);
                        }
                    }),
                    catchError((error) =>
                        this.errorService.handleError(action, AuthActions.patchCirkloUserInfoFailure, error),
                    ),
                ),
            ),
        ),
    );

    getCollectorEconomies$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.getCollectorEconomies),
            switchMap((action) =>
                this.storesService.appListStoreEconomies(action.storeId).pipe(
                    map((payload) => AuthActions.getCollectorEconomiesSuccess({ payload })),
                    catchError((error) =>
                        this.errorService.handleError(action, AuthActions.getCollectorEconomiesFailed, error),
                    ),
                ),
            ),
        ),
    );

    getAllEconomies$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.searchEconomies),
            switchMap((action) =>
                this.economiesService
                    .appListEconomies(
                        action.filter.sortOrder,
                        action.filter.sortBy,
                        action.filter.page,
                        action.filter.size,
                        action.filter.searchString,
                        action.filter.lat,
                        action.filter.lng,
                        action.filter.country,
                        action.filter.postalCode,
                    )
                    .pipe(
                        map((payload) => AuthActions.searchEconomiesSuccess({ payload, page: action.filter.page })),
                        catchError((error) =>
                            this.errorService.handleError(action, AuthActions.searchEconomiesFailed, error),
                        ),
                    ),
            ),
        ),
    );

    appRequestStoreEconomy$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.appRequestStoreEconomy),
            switchMap((action) =>
                this.storesService.appRequestStoreEconomy(action.storeId, action.payload).pipe(
                    map((payload) => AuthActions.appRequestStoreEconomySuccess({ payload })),
                    catchError((error) =>
                        this.errorService.handleError(action, AuthActions.appRequestStoreEconomyFailed, error),
                    ),
                ),
            ),
        ),
    );
}
