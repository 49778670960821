import { computed, inject, Injectable } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LayoutService {
    routerEvent = toSignal(inject(Router).events.pipe(filter((newUrl) => newUrl instanceof NavigationEnd)));
    isInTabsPages = computed(() => {
        const event = this.routerEvent();
        return event ? this.checkPresenceInTabPage(event) : false;
    });

    private checkPresenceInTabPage(event: NavigationEnd) {
        const splitUrl = event.urlAfterRedirects.split('/');
        return !!(splitUrl.length && splitUrl.length > 1 && splitUrl[1] === 'tabs');
    }
}
