import { TranslationKey } from '@icp/angular/i18n';
import {
    AppCirkloGetUserInfoDto,
    AppCirkloUpdateStoreInfoDto,
    AppCirkloUpdateUserInfoDto,
    AppEconomyCollectorDto,
    AppEconomyPaginationDto,
    AppListEconomiesQueryDto,
    AppRequestEconomyStoreDto,
    AppSessionDto,
    AppStoreDto,
    AppUpdateSessionProfileDto,
    AppUpdateUserDto,
    CirkloGetStoreInfoResultDto,
    SupportedLanguage,
} from '@icp/interfaces';
import { createAction, props } from '@ngrx/store';

const prefix = '[Auth]';

export const logout = createAction(`${prefix} Logout`);
export const logoutSuccess = createAction(`${prefix} Logout success`);
export const logoutFailed = createAction(`${prefix} Logout failure`, props<{ error: string }>());

export const updateUser = createAction(`${prefix} Update user`, props<{ payload: AppUpdateUserDto }>());

export const setLanguage = createAction(`${prefix} Set language`, props<{ language: SupportedLanguage }>());

export const getAppSession = createAction(`${prefix} Get app session`, props<{ sessionId: string }>());
export const getAppSessionSuccess = createAction(
    `${prefix} Get app session success`,
    props<{
        session: AppSessionDto;
    }>(),
);
export const getAppSessionFailure = createAction(`${prefix} Get app session failure`, props<{ error: string }>());

export const updateAppSession = createAction(
    `${prefix} Update app session`,
    props<{ payload: AppUpdateSessionProfileDto }>(),
);
export const updateAppSessionSuccess = createAction(
    `${prefix} Update app session success`,
    props<{ session: AppSessionDto }>(),
);
export const updateAppSessionFailure = createAction(`${prefix} Update app session failure`, props<{ error: string }>());

export const listUserStores = createAction(`${prefix} List user stores`);
export const listUserStoresSuccess = createAction(
    `${prefix} List user stores success`,
    props<{ stores: AppStoreDto[] }>(),
);
export const listUserStoresFailure = createAction(`${prefix} List user stores failure`, props<{ error: string }>());

export const getCirkloStoreInfo = createAction(`${prefix} Get cirklo store info`);
export const getCirkloStoreInfoSuccess = createAction(
    `${prefix} Get cirklo store info success`,
    props<{ payload: CirkloGetStoreInfoResultDto }>(),
);
export const getCirkloStoreInfoFailure = createAction(
    `${prefix} Get cirklo store  info failure`,
    props<{ error: string }>(),
);

export const patchCirkloStoreInfo = createAction(
    `${prefix} Patch cirklo store  info`,
    props<{ nextUrl: string; successMessage?: TranslationKey; payload: AppCirkloUpdateStoreInfoDto }>(),
);
export const patchCirkloInfoStoreSuccess = createAction(
    `${prefix} Patch cirklo store  info success`,
    props<{ payload: CirkloGetStoreInfoResultDto }>(),
);
export const patchCirkloStoreInfoFailure = createAction(
    `${prefix} Patch cirklo store  info failure`,
    props<{ error: string }>(),
);

export const getCirkloUserInfo = createAction(`${prefix} Get cirklo user info`);
export const getCirkloUserInfoSuccess = createAction(
    `${prefix} Get cirklo user  info success`,
    props<{
        payload: AppCirkloGetUserInfoDto;
    }>(),
);
export const getCirkloUserInfoFailure = createAction(
    `${prefix} Get cirklo user  info failure`,
    props<{ error: string }>(),
);

export const patchCirkloUserInfo = createAction(
    `${prefix} Patch cirklo user info`,
    props<{ nextUrl: string; successMessage?: TranslationKey; payload: AppCirkloUpdateUserInfoDto }>(),
);
export const patchCirkloInfoUserSuccess = createAction(
    `${prefix} Patch cirklo user  info success`,
    props<{ payload: AppCirkloGetUserInfoDto }>(),
);
export const patchCirkloUserInfoFailure = createAction(
    `${prefix} Patch cirklo user  info failure`,
    props<{ error: string }>(),
);

export const getCollectorEconomies = createAction(`${prefix} Get collector economies`, props<{ storeId: string }>());
export const getCollectorEconomiesSuccess = createAction(
    `${prefix} Get collector economies success`,
    props<{ payload: AppEconomyCollectorDto[] }>(),
);
export const getCollectorEconomiesFailed = createAction(
    `${prefix} Get collector economies failed`,
    props<{ error: string }>(),
);

export const searchEconomies = createAction(
    `${prefix} Get All Economies`,
    props<{ filter: AppListEconomiesQueryDto }>(),
);
export const searchEconomiesSuccess = createAction(
    `${prefix} Get All Economies success`,
    props<{ payload: AppEconomyPaginationDto; page?: number }>(),
);
export const searchEconomiesFailed = createAction(`${prefix} Get All Economies failed`, props<{ error: string }>());

export const appRequestStoreEconomy = createAction(
    `${prefix} Request store economy`,
    props<{ storeId: string; payload: AppRequestEconomyStoreDto }>(),
);
export const appRequestStoreEconomySuccess = createAction(
    `${prefix} Request store economy success`,
    props<{ payload: AppEconomyCollectorDto }>(),
);
export const appRequestStoreEconomyFailed = createAction(
    `${prefix} Request store economy failed`,
    props<{ error: string }>(),
);
